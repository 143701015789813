import './App.scss';

import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';

import Menu from './components/menu/menu';
import Game from './components/game/game';
import HowTo from './components/howto/howto';
import Info from './components/info/info';
import Packs from './components/packs/packs';

function App() {
  const TRACKING_ID = 'G-8532Q3P6KN';
	ReactGA.initialize(TRACKING_ID);

  const [isPlaying, setIsPlaying] = useState(false);
  const [orientation, setOrientation] = useState('landscape-primary');
  const [showHowTo, setShowHowTo] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showPacks, setShowPacks] = useState(false);

  const [packs, setPacks] = useState(['dragoncon', '2023']);

  useEffect(() => {
    try {
      console.log('version: 1.0.6');
      window.screen.orientation.addEventListener('change', handleOrientation);
      setOrientation(window.screen.orientation.type);
      return () => {
          window.screen.orientation.removeEventListener('change', handleOrientation);
      }; 
    } catch (error) {
      if (window.orientation !== null && window.orientation !== undefined) {
        window.addEventListener('deviceorientation', handleIosOrientation);
        setOrientation(convertIosOrientation(window.orientation));
        return () => {
            window.removeEventListener('deviceorientation', handleIosOrientation);
        };
      } else {
        console.log('nothing supported');
      }
    }
  });

  useEffect(() => {
    let packsStorage = localStorage.getItem('packs');
    if (packsStorage) {
      setPacks(JSON.parse(packsStorage));
    } else {
      localStorage.setItem('packs', JSON.stringify(packs));
    }

    try {
      document.addEventListener("visibilitychange", handleVisibilityChange);
      return () => {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
      };
    } catch (error) {
      
    }
  }, []);

  function handleVisibilityChange(event) {
    if (document.hidden) {
      backToMenu();
    }
  }

  function handleIosOrientation(event) {
    let cOrientation = convertIosOrientation(window.orientation);
    if (cOrientation !== orientation) {
      setOrientation(cOrientation);
    }
  }

  function convertIosOrientation(degrees) {
    switch (degrees) {
      case 0:
        return 'portrait-primary';
      case 90:
        return 'landscape-secondary';
      case -90:
        return 'landscape-primary';
      case 180:
        return 'portrait-secondary';
      default:
        return 'landscape-primary';
    }
  }

  function handleOrientation(event) {
    let cOrientation = window.screen.orientation.type;
    if (cOrientation !== orientation) {
      setOrientation(cOrientation);
    }
  }

  function startGame(e) {
    setIsPlaying(true);
  }

  function showHowToClick(e) {
    setShowHowTo(true);
  }

  function showInfoClick(e) {
    setShowInfo(true);
  }

  function showPacksClick(e) {
    setShowPacks(true);
  }

  function backToMenu() {
    setIsPlaying(false);
    setShowHowTo(false);
    setShowInfo(false);
    setShowPacks(false);
  }

  function renderGame() {
    if (isPlaying) {
      return (
        <Game backToMenu={backToMenu} orientation={orientation} packs={packs} />
      );
    } else if (showHowTo) {
      return (
        <HowTo backToMenu={backToMenu} />
      );
    } else if (showInfo) {
      return (
        <Info backToMenu={backToMenu} />
      );
    } else if (showPacks) {
      return (
        <Packs backToMenu={backToMenu} packs={packs} setPacks={setPacks} />
      );
    } else {
      return (
        <Menu startGame={startGame} showHowTo={showHowToClick} showInfo={showInfoClick} showPacks={showPacksClick} />
      );
    }
  }

  function getRotateMessage() {
    switch (orientation) {
      case 'portrait-primary':
        return 'Hold your phone in LANDSCAPE mode.';
      case 'portrait-secondary':
        return 'Hold your phone in LANDSCAPE mode.';
      case 'landscape-secondary':
        return 'The other LANDSCAPE mode. (Flip it over)'
      default:
        break;
    }
  }

  return (
    <div className="App">
      <div className="game">
        {renderGame()}
      </div>
      {orientation !== 'landscape-primary' && (
        <div className="rotate">
          {getRotateMessage()}
        </div>
      )}       
    </div>
  );
}

export default App;
