import './packs.scss';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { useEffect, useState } from 'react';

// Packs to create
// - Star Trek

function Packs(props) {

    function toggleChange(e) {
        let packId = e.target.id;
        let packs = [...props.packs];
        if (e.target.checked) {
            packs.push(packId);
        } else {
            let ind = packs.indexOf(packId);
            if (ind > -1) {
                packs.splice(ind, 1);
            }
        }
        if (packs.length === 0) {
            alert('You must have at least one pack selected!');
            return;
        }
        localStorage.setItem('packs', JSON.stringify(packs));
        props.setPacks(packs);
    }

	return (
		<div className='packsWrapper'>
			<div className='title'>Packs</div>
			<div className='text'>
				<p>Select the packs you'd like to include.</p>
                <div className='pack'>
                    <Form.Check
                        type="switch"
                        id="dragoncon"
                        label="DragonCon - Your basic DragonCon prompts. The staples."
                        onChange={toggleChange}
                        checked={props.packs.indexOf('dragoncon') > -1}
                    />
                </div>
                <div className='pack'>
                    <Form.Check
                        type="switch"
                        id="2023"
                        label="2023 Guests - Featured guests for 2023."
                        onChange={toggleChange}
                        checked={props.packs.indexOf('2023') > -1}
                    />
                </div>
                <div className='pack'>
                    <Form.Check
                        type="switch"
                        id="2024"
                        label="2024 Guests - Featured guests for 2024."
                        onChange={toggleChange}
                        checked={props.packs.indexOf('2024') > -1}
                    />
                </div>
                <div className='pack'>
                    <Form.Check
                        type="switch"
                        id="dnd"
                        label="D&D - Terms, monsters, items from Dungeons & Dragons."
                        onChange={toggleChange}
                        checked={props.packs.indexOf('dnd') > -1}
                    />
                </div>
                <div className='pack'>
                    <Form.Check
                        type="switch"
                        id="marvel"
                        label="Marvel - Popular characters from Marvel Comics."
                        onChange={toggleChange}
                        checked={props.packs.indexOf('marvel') > -1}
                    />
                </div>
                <div className='pack'>
                    <Form.Check
                        type="switch"
                        id="starwars"
                        label="Star Wars - Popular characters and terms from Star Wars."
                        onChange={toggleChange}
                        checked={props.packs.indexOf('starwars') > -1}
                    />
                </div>
			</div>
			<Button variant="secondary" size='md' onClick={props.backToMenu}>Back</Button>
		</div>
	);
}

export default Packs;
