import './howto.scss';
import Button from 'react-bootstrap/Button';

import { useEffect, useState } from 'react';

function HowTo(props) {
	return (
		<div className='howtoWrapper'>
			<div className='title'>How to Play</div>
			<div className='text'>
				<p>It's just like the game Heads Up!</p>
				<ol>
					<li>Always hold your phone in LANDSCAPE mode</li>
					<li>Press the START GAME button in the menu</li>
					<li>Hold your phone up to your head (facing outward) and take a bow to start</li>
					<li>Have your friends (or strangers) give you clues while you guess</li>
					<li>If you guess it correctly, take a bow</li>
					<li>If you get frustrated and want to pass, look to the sky</li>
					<li>You have 60 seconds to get as many as you can</li>
				</ol>
				<p>Have fun!</p>
			</div>
			<Button variant="secondary" size='md' onClick={props.backToMenu}>Back</Button>
		</div>
	);
}

export default HowTo;
