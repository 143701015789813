import './menu.scss';
import Button from 'react-bootstrap/Button';

import { useEffect, useState } from 'react';

function Menu(props) {
	const [showMenu, setShowMenu] = useState(false);

	return (
		<div className='menuWrapper'>
			<div className='title'><span className='purple'>Dragon</span>Up!</div>
			<div className='buttons'>
				<Button variant="success" size='lg' onClick={props.startGame}>Start Game</Button>
				<Button variant="secondary" size='sm' onClick={props.showPacks}>Prompt Packs</Button>
				<Button variant="secondary" size='sm' onClick={props.showHowTo}>How To Play</Button>
				<Button variant="secondary" size='sm' onClick={props.showInfo}>About</Button>
			</div>
			<div className='ads'>
				<div>
					Do you play Wordle?<br /><Button variant="outline-secondary" size='sm' href="https://dragoncordle.zachwerden.com" >Try DRAGONCordle</Button>
				</div>
				<div>
					Want a virtual bingo card?<br /><Button variant="outline-secondary" size='sm' href="https://dconbingo.zachwerden.com" >Try DragonCon Bingo</Button>
				</div>
			</div>
		</div>
	);
}

export default Menu;
