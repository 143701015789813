import './info.scss';
import Button from 'react-bootstrap/Button';

import { useEffect, useState } from 'react';

function Info(props) {
	return (
		<div className='infoWrapper'>
			<div className='title'>About</div>
			<div className='text'>
				<p>This game was made by Zach Werden. It was as rushed as my cosplays. Apologies for any bugs or missing features.</p>
				<p>This game collects no user data and makes no money. It's simply a gift to the DragonCon community!</p>
				<p>If you like this, try out my other projects...</p>
				<p><a href='https://dragoncordle.zachwerden.com' >DRAGONCordle</a> - It's like Wordle, but all the answers are DragonCon themed!</p>
				<p><a href='https://epiclootz.fun' >EpicLootz</a> - My D&D content site built around my instagram. All content and images are AI generated!</p>
			</div>
			<Button variant="secondary" size='md' onClick={props.backToMenu}>Back</Button>
		</div>
	);
}

export default Info;
