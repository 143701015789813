import './game.scss';
import Button from 'react-bootstrap/Button';
import Countdown from 'react-countdown';
import ReactGA from 'react-ga4';

import { useEffect, useState } from 'react';

import useSound from 'use-sound';
import success from "../../assets/music/success.mp3";
import fail from "../../assets/music/fail.mp3";
import gameOver from "../../assets/music/gameOver.mp3";
import beep from "../../assets/music/beep.mp3";
import go from "../../assets/music/go.mp3";

//TODO
//-personal pack
//-High Score
//-Settings
//  -fandom packs

//bug

function Game(props) {
    const [prompts, setPrompts] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [score, setScore] = useState(0);
    const [gamma, setGamma] = useState(90);
    // 1 - get ready, 2 - countdown, 3 - playing, 4 - game over/score
    const [step, setStep] = useState(1);
    const [ready, setReady] = useState(false);

    const [tilt, setTilt] = useState('neutral');
    const [lastAnswer, setLastAnswer] = useState(0);
    const [countdownEnd, setCountdownEnd] = useState(0);
    const [readyEnd, setReadyEnd] = useState(0);

    const [debugString, setDebugString] = useState('');

    const [successAudio] = useSound(
        success
    );
    const [failAudio] = useSound(
        fail,
        { volume: 0.25 }
    );
    const [gameOverAudio] = useSound(
        gameOver,
        { volume: 0.5 }
    );
    const [beepAudio] = useSound(
        beep,
        { volume: 0.25 }
    );
    const [goAudio] = useSound(
        go
    );


    //ready - hold up to head and tilt down to start (detect orientation left or right)
    //countdown - 3...2...1
    //tilt down for correct
    //tilt up for pass     90 head to left

    useEffect(() => {
        if (typeof DeviceOrientationEvent.requestPermission === 'function') {
            DeviceOrientationEvent.requestPermission()
                .then(permissionState => {
                    if (permissionState === 'granted') {
                        window.addEventListener('deviceorientation', handleOrientation);
                    }
                })
                .catch(console.error);
        } else {
            if (window.DeviceOrientationEvent) {
                window.addEventListener('deviceorientation', handleOrientation);
            } else {
                //TODO: ERROR SCREEN
                console.log("DeviceOrientationEvent is not supported");
            }
        }

        if (prompts.length === 0) {
            let allPrompts = [];
            let packs = props.packs;
            packs.forEach(pack => {
                let promptsData = require('../../data/' + pack + '.json');
                allPrompts = allPrompts.concat(promptsData);
            });
            allPrompts = allPrompts.filter(onlyUnique);

            let mixedPrompts = shuffle(allPrompts);
            setPrompts(mixedPrompts);
        }

        return () => {
            window.removeEventListener('deviceorientation', handleOrientation);
        };      
    });

    useEffect(() => {
        if (props.orientation !== 'landscape-primary') return;

        if (tilt !== 'neutral' && gamma >= 70 || gamma <= -70) {
            setTilt('neutral');
            if (ready && step === 1) {
                beepAudio();
                setReadyEnd(Date.now() + 5000);
                setStep(2);
            }
        }

        if (step === 1) {
            if (tilt === 'neutral' && !ready && (gamma <= 40 && gamma > 5)) {
                setReady(true);
            }
        }

        let answerDate = new Date();
        let dateDiff = answerDate - lastAnswer;

        if (tilt === 'neutral' && gamma >= -40 && gamma < -10 && dateDiff > 1000) {
            let t = 'up';
            setTilt(t);
            if (step === 3) {
                setDebugString(`UP - gamma: ${gamma}, orientation: ${props.orientation}`);
                setLastAnswer(answerDate);
                setCurrentIndex(currentIndex + 1);
                failAudio();
            }
        }

        if (tilt === 'neutral' && gamma <= 40 && gamma > 10 && dateDiff > 1000) {
            let t = 'down';
            setTilt(t);
            if (step === 3) {
                setDebugString(`DOWN - gamma: ${gamma}, orientation: ${props.orientation}`);
                setLastAnswer(answerDate);
                setCurrentIndex(currentIndex + 1);
                setScore(score + 1);
                successAudio();
            }            
        }
    }, [gamma]);

    const secondsRenderer = ({ hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return null;
        } else if (seconds === 0 && minutes === 1) {
            return <span>60</span>;
        } else {
            // Render a countdown
            return <span>{seconds}</span>;
        }
    };

    function shuffle(array) {
        let currentIndex = array.length,  randomIndex;
        
        // While there remain elements to shuffle.
        while (currentIndex != 0) {
        
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex);
            currentIndex--;
        
            // And swap it with the current element.
            [array[currentIndex], array[randomIndex]] = [
            array[randomIndex], array[currentIndex]];
        }
        
        return array;
    }

    function onlyUnique(value, index, array) {
        return array.indexOf(value) === index;
    }

    function handleOrientation(event) {
        const gamma = Math.round(event.gamma);
        if (step !== 2 && step !== 4 && props.orientation === 'landscape-primary') {
            setGamma(gamma);
        }        
    }

    function startGame() {
        setCountdownEnd(Date.now() + 60000);
        goAudio();
        setStep(3);
    }

    function endGame() {
        window.removeEventListener('deviceorientation', handleOrientation);
        gameOverAudio();
        ReactGA.event({
			category: 'Game',
			action: 'Played',
		});
        setStep(4);
    }

    function backToMenu() {
        props.backToMenu();
    }

    function playBeep(e) {
        if (e.total < 6000) {
            beepAudio();
        }
    }

    function shareScore(e) {
		let resultsToCopy = `I just got a score of ${score} while playing DragonUp!`;
		resultsToCopy += '\r\n\r\nhttps://dragonup.zachwerden.com';
		ReactGA.event({
			category: 'Share',
			action: 'score',
		});
		if (navigator.share) {
			navigator.share({
				text: resultsToCopy,
			});
		} else {
			navigator.clipboard.writeText(resultsToCopy);
		}
	}

    function renderGame() {
        let gameMain = null;

        if (tilt === 'neutral') {
            gameMain = (
                <div>{prompts[currentIndex]}</div>
            );
        }

        if (tilt === 'down') {
            gameMain = (
                <div className='success'>SUCCESS!</div>
            );
        }

        if (tilt === 'up') {
            gameMain = (
                <div className='fail'>pass...</div>
            );
        }

        return (
            <div className='game'>
                <div className='gameMain'>
                    {gameMain}
                </div>
                <div className='gameCountdown'>
                    <Countdown date={countdownEnd} onComplete={endGame}  onTick={playBeep} renderer={secondsRenderer} />
                </div>
            </div>
        )
    }

    function renderStep() {
        switch (step) {
            case 1:
                return (
                    <div className='startDirections'>
                        <div>To start, hold the device up to your head while standing straight and then tilt forward and back to begin.</div>
                        <div className='subtitle'>As if bowing to the DragonCon gods!</div>
                    </div>
                    
                );
                break;
            case 2:
                return (
                    <div className='startCountdown'>
                        <Countdown date={readyEnd} onComplete={startGame} onTick={playBeep} renderer={secondsRenderer} />
                    </div>
                );
                break;
            case 3:
                return (
                    <div>{renderGame()}</div>
                );
            case 4:
                return (
                    <div className='gameOver'>
                        <div>GAME OVER</div>
                        <div className='score'>{score}</div>
                        <div>
                            <Button variant="secondary" size='lg' onClick={shareScore}>Share Score</Button>
                            <Button variant="success" size='lg' onClick={backToMenu}>Back to Menu</Button>
                        </div>
                    </div>
                );
            default:
                break;
        }
    }

	return (
		<div className='gameWrapper'>
            {/* <div className='debug'>{gamma} {tilt.toString()} {score} {props.orientation}</div> */}
            {/* <div className='debug'>{gamma} {debugString}</div> */}
            {renderStep()}
		</div>
	);
}

export default Game;
